@import "colors";


.theme-light {
    @each $name, $color in $colors {
        #{$name}: nth($color, 1);
    }
}

.theme-dark {
    @each $name, $color in $colors {
        #{$name}: nth($color, 2);
    }
}
