@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
  


$scilog-colors: (
    50 : #f0f0f0, // background
    100 : #b3c9d0,
    200 : #80a6b1,
    300 : #4d8292,
    400 : #26677a,
    500 : #004c63,
    600 : #00455b,
    700 : #003c51,
    800 : #003347,
    900 : #002435,
    A100 : #6dc1ff,
    A200 : #3aacff,
    A400 : #0796ff,
    A700 : #ec2300,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
  

/* ======== Angular material custom themes ======== */ 
$my-custom-primary: mat.define-palette($scilog-colors, 900, 700, 900);
$my-custom-accent: mat.define-palette($scilog-colors, 500, 200, A100);
$my-custom-warn: mat.define-palette($scilog-colors, A700);

$my-custom-theme: mat.define-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);

@include mat.all-legacy-component-themes($my-custom-theme);


.warning-snackbar {
    background: rgb(236, 55, 9);
    color: rgb(255, 255, 255)
  }
.resolved-snackbar {
    background: rgb(13, 153, 24);
    color: rgb(255, 255, 255)
  }